import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Close } from '@mui/icons-material';
import { useStateContext } from '../Context/ContextProvider';
import {FaArrowTurnUp} from 'react-icons/fa6'
import {IoIosArrowDown} from 'react-icons/io'
import { useEffect } from 'react';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    // p: 4,
};
function AuditLog({auditLog,value}) {
    const { wareHouse,
        WareHouse,sku}=useStateContext()
        useEffect(()=>{
            WareHouse()
        },[])
        var date=new Date().getFullYear()+"-"+new Date().getMonth()+"-"+new Date().getDate()
        var time=new Date().getHours()+":"+new Date().getMinutes()
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <>
            <button onClick={handleOpen} type="button" className="close" >
                <span ><IoIosArrowDown style={{ fontSize: "25px" }} /></span>
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{ color: "white", background: "#44688C", padding: "1rem", display: "flex", justifyContent: "space-between" }}>
                        <h5>View Audit Trail</h5>
                        <div>
                            {/* <button style={{ border: "none", background: "none" }}><VscChromeRestore style={{ color: "whitesmoke", fontSize: "20px" }} /></button> */}
                            <button onClick={() => handleClose()} style={{ border: "none", background: "none" }}><Close style={{ color: "whitesmoke" }} /></button>
                        </div>
                    </div>
                    <div className='main_audit_log' style={{
                        height:"80vh",
                        padding:"1rem",
                        background:"#F9F9F9"
                        
                    }}>

                    <ul className="nav nav-tabs mb-3" id="ex4" role="tablist">
                      <li className="nav-item" role="presentation">
                        <a className="nav-link active" id="ex4-tab-1" data-mdb-toggle="tab" href="#ex4-tabs-1" role="tab" aria-controls="ex4-tabs-1" aria-selected="true">Audit Trail</a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="nav-link" id="ex4-tab-2" data-mdb-toggle="tab" href="#ex4-tabs-2" role="tab" aria-controls="ex4-tabs-2" aria-selected="false">Stock Item History</a>
                      </li>
                      
                    </ul>
                    <div className="tab-content" id="ex4-content">
                      <div className="tab-pane fade show active" id="ex4-tabs-1" role="tabpanel" aria-labelledby="ex4-tab-1">
                      <div className='audit_table_log'>

<table className="table">
<thead>
    <tr>

    <th scope="col">Audit Trail Date</th>
    <th scope="col">Audit Type</th>
    <th scope="col">Audit Text</th>
    <th scope="col">Username</th>

    </tr>
</thead>
<tbody>
    {auditLog?.map((item)=>{
        return(
            <tr >
    
            <td className='bg'>{item.audit_date}</td>
            <td className='bg'>{item.audit_type}</td>
            <td className='bg'>{item.audit_text}</td>
            <td className='bg'>{item.username}</td>
         
            </tr>
        )
    })}
 

</tbody>
</table>
</div>

                      </div>
                      <div className="tab-pane fade" id="ex4-tabs-2" role="tabpanel" aria-labelledby="ex4-tab-2">
                      <div className='row'>
                        <div className='col-1'>
                            <label>Location:</label>
                            </div> 
                        <div className='col-8'>
                            <select style={{padding:".3rem",border:"1px solid lightgray",background:"none"}}>
                                {wareHouse?.map((item)=>{
                                    return(
                                    <option>{item.name}</option>
                                    )
                                })}
                            </select>
                            </div> 

                      </div>
                      <div className='audit_table_log'>

                        <table className="table">
                        <thead>
                            <tr>
                        
                            <th scope="col">Change</th>
                            <th scope="col">Time</th>
                            <th scope="col">Stock Level</th>
                            <th scope="col">Stock Value</th>
                            <th scope="col">Note</th>
                            <th scope="col">Stock Level Change</th>
                            <th scope="col">Stock Value Change</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                            
                            <td className='bg'><FaArrowTurnUp style={{color:"green"}}/></td>
                            <td className='bg'>{date+time}</td>
                            <td className='bg'>{value[0]?.level}</td>
                            <td className='bg'>{value[0]?.value}</td>
                            <td className='bg'>OPENING STOCK</td>
                            <td className='bg'>{value[0]?.level}</td>
                            <td className='bg'>{value[0]?.value}</td>
                            </tr>
                     
                        </tbody>
                        </table>
                      </div>

                      </div>
                     
                    </div>
                    </div>
                    <div className='row' style={{ backgroundColor: "#f0f0f0", borderTop: "1px solid #dee2e6!important",padding:"1.3rem",paddingRight:"0",width:"100%",margin:"0%" }}>
                      <div style={{ display: "flex", justifyContent: "end" }}>
                      {/* <button  style={{ border: "1px solid lightgray", background: "#5C7FA5", color: "whitesmoke", padding: ".5rem 1rem", margin: "0 .5rem" }}><FiSave style={{ color: "whitesmoke" }} /> Save</button> */}
                          <button onClick={handleClose} style={{ border: "1px solid lightgray", background: "#EAEBEC", padding: ".5rem 1rem" }}>Cancel</button>
                      </div>
                  </div>
                </Box>

            </Modal>
        </>
    )
}

export default AuditLog