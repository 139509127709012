// import React from 'react'
import React, {useState } from 'react'
import { RiQuestionLine } from 'react-icons/ri';
import { VscChromeRestore } from 'react-icons/vsc';
import { useStateContext } from '../../Context/ContextProvider';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { LiaSave } from 'react-icons/lia';
import { AiOutlineCaretDown } from 'react-icons/ai'
import ListingDescription from './ListingDescription';
import ExtendPro from './ExtendPro';
const style = {
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  // p: 4,
};
function Checks({ data, id, pro, setvalue, value }) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const opene = Boolean(anchorEl);
  const [value1,setvalue1]=useState({
   sku:'',
   name:'',
   barcode: null,
   purchase_price: null,
   retail_price: null,
   meta_data: null,
   tax: null,
   category: null,
   images: [],
   var_pricing: [],
   var_titles: [],
   var_desc: [],
   var_extend:[],
   id:""
  })
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosee = () => {
    setAnchorEl(null);
  };
  const [expand, setexpand] = useState(true)
  const [tax, settax] = useState(false)
  const[auditLog,setlog]=useState([])
  // console.log(auditLog,"qwertyuiopoiuytwertyuiuytrewertyui");
  const { editData,base_url,token,imgBase_url, inventoryData, filter, datacetagory, search, wareHouse, WareHouse, allCetagore, setinventoryData, addinventory, getinventoryFun, getinventory, handleImageChange} = useStateContext()
  const [getimage,setImage]=useState([])
  const remove=(id)=>{
    console.log(id);
    const data=selectedImages.filter((item,index)=>{
      if(index!==id){
        return item
      }
    })
    setSelectedImages(data)
    Createimge()
  }
  const handleOpen = () => {
    setvalue(pro)
    setOpen(true);
    setvalue1({
      sku:pro?.sku,
      name:pro?.name,
      barcode: pro?.barcode,
      purchase_price: pro?.purchase_price,
      retail_price: pro?.retail_price,
      meta_data: pro?.meta_data,
      tax: pro?.tax,
      category: pro?.category,
      var_pricing: pro?.var_pricing,
      var_titles: pro?.var_titles,
      var_desc:pro?.var_desc,
      var_extend:pro?.var_extend,
      id:pro?.id
     })
     setImage(pro?.images)
    setSelectedImages([])
    // fetch(`${base_url}auditproduct/${pro.id}`,{
    //   headers:{
    //     'Authorization': token,
    //   }
    // })
    //   .then(res => res.json())
    //   .then(resp => {
    // console.log(resp);
    // setlog(resp)
    //   })

  };
  const updateVariation=()=>{
    fetch(`${base_url}variations-update/${value1.id}`,{
      method:"PUT",

      headers:{
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body:JSON.stringify(value1)
    })
      .then(res => res.json())
      .then(resp => {
    console.log(resp);
        handleClose()
        getinventoryFun()
      })
     
        // console.log("ok");
        
          const data = new FormData();
        
          selectedImages.map((image,idx) => {
            data.append(`var_imgs[]`, image);
          })
         
        const image = JSON.stringify(getimage);
            data.append(`images`, image);
            data.append(`var_id`, value1.id);
          fetch(`${base_url}variations/${value1.id}/images`,{
            method: "POST",
            headers: {
                'Authorization': token,
            },
            body: data
        
          })
          .then(res => res.json())
          .then(resp => {
        console.log(resp);
        getinventoryFun()
        setSelectedImages([])
        })
        
  }
  const handleClose = () => {
    setOpen(false);
  };
  console.log(pro);
  const sendData = () => {
    editData(value)
    handleClose()
  }
  const delimage=(id)=>{

  let array=getimage?.filter((item)=>{
      if(item.id!==id){
        return item
      }
    })
    setImage(array)
    const image = JSON.stringify(array);
  const data = new FormData();

    data.append(`images`, image);
    data.append("var_id",value1?.id)
  fetch(`${base_url}variations/${value1.id}/images`,{
    method: "POST",
    headers: {
        
        'Authorization': token,
    },
    body: data

  })
  .then(res => res.json())
  .then(resp => {
console.log(resp);
getinventoryFun()

})
  }
  const [selectedImages, setSelectedImages] =useState([]);
const Createimge=()=>{
 var  formData = new FormData();
  var pic = selectedImages.map((image, index) => {
    formData.append(`images`, image);
    console.log(image);
    var url = URL.createObjectURL(image);
    return(
    <>{index === 0 ?
      <div>
        <div style={{display:"flex",justifyContent:`${getimage.length>0?"end":"space-between"}`}}>
        {getimage.length>0?"":<p className='mx-1'>Mian image</p>}
          <div style={{cursor:"pointer",fontWeight:"bold"}} onClick={()=>remove(index)}>x</div>
        </div>
         
        <div style={{ border: "1px solid lightgray", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }} className='main_img'>
          <img className='show_pic' src={url} />
        </div>
      </div>
      :
      <div>
        <div style={{display:"flex",justifyContent:"end"}}>
        {/* <p className='mx-1'>Mian image</p> */}
          <div style={{cursor:"pointer",fontWeight:"bold"}} onClick={()=>remove(index)}>x</div>
        </div>
      <div  style={{ border: "1px solid lightgray", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column",marginTop:"25px" }} className='main_img'>
        {/* <div onClick={()=>delimage(item.id)}>x</div> */}
        <img className='show_pic' src={url} />

      </div>
      </div>
    }
    </>
    )
  })
  return pic
}
  const handleImageChange1=(e)=>{
    if(e?.target?.files){

      const files = Array.from(e.target.files);
      let images=selectedImages.concat(files)
      console.log(images);
      
      setSelectedImages(images);
      Createimge()
    }
    else{
      e.preventDefault();
      const files = Array.from(e.dataTransfer.files);
      setSelectedImages(files);
      Createimge()
    }
  }
  console.log(value1);
  return (
    <>
    <button type="button" onClick={handleOpen} style={{ border: "none", color: "#0D9CFB", background: "none", fontSize: "12px" }}>
      {data}
    </button>
    <div id='inventory'>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        style={{ overflowY: "scroll" }}
      >
        <Box sx={{ ...style, width: expand ? 1200 : 800 }}>
          <div  >
            <div  >
              <div className="modal-content">
                <div id='inventory_header' className='modal-header'>
                  <h5 className="modal-title" id="exampleModalLabel">Product Details {data} |{value1?.name} </h5>
                  <div>
                    {/* <AuditLog auditLog={auditLog} value={value?.pro_inventory}/> */}
                    <button onClick={() => setexpand(!expand)} type="button" className="close" >
                      <span ><VscChromeRestore style={{ fontSize: "25px" }} /></span>
                    </button>
                    <button onClick={handleClose} type="button" className="close">
                      <span style={{ fontSize: "25px" }}  >×</span>
                    </button>
                  </div>
                </div>
                <div className="modal-body">
                  <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a className="nav-link active" id="ex1-tab-1" data-mdb-toggle="tab" href="#ex1-tabs-1" role="tab" aria-controls="ex1-tabs-1" aria-selected="true">General <RiQuestionLine /></a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a className="nav-link" id="ex1-tab-2" data-mdb-toggle="tab" href="#ex1-tabs-2" role="tab" aria-controls="ex1-tabs-2" aria-selected="false">Images <RiQuestionLine /></a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a className="nav-link" id="ex1-tab-3" data-mdb-toggle="tab" href="#ex1-tabs-3" role="tab" aria-controls="ex1-tabs-3" aria-selected="false">Listing Description <RiQuestionLine /></a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a className="nav-link" id="ex1-tab-4" data-mdb-toggle="tab" href="#ex1-tabs-4" role="tab" aria-controls="ex1-tabs-4" aria-selected="false">Extended properties <RiQuestionLine /></a>
                    </li>
                  </ul>
                  <div className="tab-content" id="ex1-content">
                    <div className="tab-pane fade show active" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1">
                      <div className='genral_inventory'>
                        <h5 style={{ margin: "0 1rem", color: "#5770AD" }} >General:</h5>
                        <div className='row'>
                          <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }} className='col-3'>

                            <label for="item_number" >Item Number:</label>
                          </div>
                          <div className='col-9'>
                            <input value={value1?.sku} disabled style={{ cursor: 'not-allowed' }} id='item_number' className='w-75 py-2 px-2 rounded border' type='text' placeholder='Item number' />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-3' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                            <label for="item_title" >Item title:</label>
                          </div>
                          <div className='col-9'>
                            <input value={value1?.name} onChange={(e) => setvalue1({ ...value1, name: e.target.value })} id='item_title' className=' w-75 py-2 px-2 rounded border' type='text' />

                          </div>
                        </div><div className='row'>
                          <div className='col-3' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                            <label for="item_barcode" >Barcode:</label>
                          </div>
                          <div className='col-9'>
                            <input value={value1?.barcode} id='item_barcode' onChange={(e) => setvalue1({ ...value1,barcode: e.target.value })} className=' w-75 py-2 px-2 rounded border' type='text' />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-3' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                            <label for="item_price">Purchase Price:</label>
                          </div>
                          <div className='col-9'>
                            <input id='item_price' onChange={(e) => setvalue1({ ...value1, purchase_price: e.target.value })} value={value1?.purchase_price} className='py-2 px-2 rounded border' type='number' />

                          </div>
                        </div><div className='row'>
                          <div className='col-3' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                            <label for="Retail price">Retail Price:</label>
                          </div>
                          <div className='col-9'>
                            <input id='Retail price' onChange={(e) => setvalue1({ ...value1, retail_price: e.target.value })} value={value1?.retail_price} className='py-2 px-2 rounded border' type='number' />

                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-3' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                            <label for="Tax">Tax:</label>
                          </div>
                          <div className='col-9'>
                            <input id='Tax' disabled={tax} style={{ cursor: `${tax ? "not-allowed" : "pointeroko"}` }} onChange={(e) => setvalue1({ ...value1, tax: e.target.value })} value={value1?.tax} className='py-2 px-2 rounded border' type='number' />
                            <input id='contry_tax' onChange={(e) => settax(e.target.checked)} type='checkbox' className='mx-2' />
                            <label for="contry_tax">Country specific rate</label>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-3' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                          </div>
                          <div className='col-9'>
                            <input id='contry_tax' type='checkbox' className='mx-2' />
                            <label for="contry_tax">Scan serial number when processing orders</label>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-3' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                            <label for="Categore">Category:</label>
                          </div>
                          <div className='col-9'>
                            <select id='Categore' value={value1?.category} onChange={(e) => setvalue1({ ...value1, category: e.target.value })} className=' w-25 py-2 px-2 rounded border' type='text' placeholder='Categore'>
                              <option hidden>Select Categore</option>
                              {allCetagore?.map((item) => {
                                return (
                                  <option value={item.id}>{item.name}</option>

                                )
                              })}
                            </select>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-3' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                            <label for="meta_data">Meta Data:</label>


                          </div>
                          <div className='col-9'>
                            <textarea id='meta_data' value={value1?.meta_data} onChange={(e) => setvalue1({ ...value1, meta_data: e.target.value })} className='w-50 py-2 px-2 rounded border' type='text' />

                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="tab-pane fade" id="ex1-tabs-2" role="tabpanel" aria-labelledby="ex1-tab-2">
                      <div className='image_add'>
                        <label style={{ background: "#EAEBEC", padding: "0.5rem 1rem", border: "1px solid lightgray", margin: "0.2rem" }} for={"img"}>Add Image</label>
                        <button
                          id="demo-positioned-button"
                          aria-controls={open ? 'demo-positioned-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={handleClick}
                          style={{ background: "#EAEBEC", borderRadius: "0px", padding: ".5rem", border: "1px solid lightgray" }}
                        >
                          <AiOutlineCaretDown />
                        </button>
                        <Menu
                          id="demo-positioned-menu"
                          aria-labelledby="demo-positioned-button"
                          anchorEl={anchorEl}
                          open={opene}
                          onClose={handleClosee}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          sx={{ paddingTop: "0px", top: "40px", left: "-105px", position: "absolute" }}
                        >
                          <MenuItem style={{ background: "#EAEBEC", border: "1px solid lightgray" }} onClick={handleClosee}>
                            <label for={"img"}>upload from Computer</label>

                          </MenuItem>
                          {/* <MenuItem style={{ background: "#EAEBEC", border: "1px solid lightgray" }} onClick={handleClosee}>upload from URL</MenuItem> */}
                        </Menu>
                        <input style={{ display: "none" }} id={"img"} type='file' multiple onChange={handleImageChange1} accept='image/*' />
                        <div>
                          <div className='img_box'>

                            {
                              getimage?.map((item, i) => {
                                return (
                                  <>{i === 0 ?
                                    <div>
                                      <div style={{display:"flex",justifyContent:"space-between"}}>
                                      <p className='mx-1'>Mian image</p>
                                        <div style={{cursor:"pointer",fontWeight:"bold"}} onClick={()=>delimage(item.id)}>x</div>
                                      </div>
                                       
                                      <div style={{ border: "1px solid lightgray", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }} className='main_img'>
                                        <img className='show_pic' src={imgBase_url+item.image_path} />
                                      </div>
                                    </div>
                                    :
                                    <div>
                                      <div style={{display:"flex",justifyContent:"end"}}>
                                 
                                        <div style={{cursor:"pointer",fontWeight:"bold"}} onClick={()=>delimage(item.id)}>x</div>
                                      </div>
                                    <div  style={{ border: "1px solid lightgray", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column",marginTop:"25px" }} className='main_img'>
                                    
                                      <img className='show_pic' src={imgBase_url + item.image_path} />

                                    </div>
                                    </div>
                                  }
                                  </>

                                )
                              })
                            }
                            <Createimge />
                          </div>
                      
                        </div>

                      </div>
                    </div>
                    <div className="tab-pane fade" id="ex1-tabs-3" role="tabpanel" aria-labelledby="ex1-tab-3">
                     
                    <ListingDescription value={value1} setvalue={setvalue1} data={value1?.var_desc} pricing_descriptions={value1?.var_pricing} product_descriptions={value1?.var_desc} title_descriptions={value1?.var_titles} />

                    </div>
                    <div className="tab-pane fade" id="ex1-tabs-4" role="tabpanel" aria-labelledby="ex1-tab-4">
                      <ExtendPro setvalue={setvalue1} value={value1} data={value1?.var_extend} />

                    </div>
                    <div className="tab-pane fade" id="ex1-tabs-5" role="tabpanel" aria-labelledby="ex1-tab-5">
                      {/* <SkuChanal setvalue={setvalue} value={value} data={value?.channel_sku} /> */}

                    </div>
                    <div className="tab-pane fade" id="ex1-tabs-6" role="tabpanel" aria-labelledby="ex1-tab-6">
                      {/* <Suppliers data={value?.pro_supplier} setvalue={setvalue} value={value} /> */}
                    </div>
                    <div className="tab-pane fade" id="ex1-tabs-7" role="tabpanel" aria-labelledby="ex1-tab-7">

                    </div>
                    <div className="tab-pane fade" id="ex1-tabs-8" role="tabpanel" aria-labelledby="ex1-tab-8">
                      {/* <InventoryModal data={value?.pro_inventory} setvalue1={setvalue} value1={value} wareHouse={wareHouse} ware_id={value?.warehouse_id} /> */}
                    </div>
                    <div className="tab-pane fade" id="ex1-tabs-9" role="tabpanel" aria-labelledby="ex1-tab-9">
                      {/* <Composition sku={data} setvalue1={setvalue} value1={value} data={value?.product_compositions} /> */}
                    </div>
                    <div className="tab-pane fade" id="ex1-tabs-10" role="tabpanel" aria-labelledby="ex1-tab-10">
                      <div className='item-state'>
                        <h5>Item Stats</h5>

                
                      </div>
                    </div>
                    <div className="tab-pane fade" id="ex1-tabs-11" role="tabpanel" aria-labelledby="ex1-tab-11">
                    
                    </div>
                    <div className="tab-pane fade" id="ex1-tabs-12" role="tabpanel" aria-labelledby="ex1-tab-12">
                    </div>
                    <div className="tab-pane fade" id="ex1-tabs-13" role="tabpanel" aria-labelledby="ex1-tab-13">
                

                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" onClick={updateVariation} className="mx-2 my-3" style={{ border: "1px solid lightgray", color: "white", padding: ".3rem 1rem", background: "#496C94" }}>
                    <LiaSave />Save
                  </button>
                  {/* <button type="button" onClick={addinventory} className="">Save</button> */}
                  <button className="mx-2" type="button" onClick={handleClose} style={{ border: "1px solid lightgray", color: "gray", padding: ".3rem 1rem" }}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  </>
  )
}

export default Checks