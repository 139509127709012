import React, { createContext, useContext, useState } from "react";
import swal from 'sweetalert';
const StateContext = createContext();
export const ContextProvider = ({ children }) => {
  const [show,setshow]=useState(false)
  const [loginData,setLogin]=useState()
  const auth=JSON.parse(localStorage.getItem("user_token"))?JSON.parse(localStorage.getItem("user_token")):loginData
  const token=`Bearer ${auth?.token}`
  const [allCetagore,setallCetagore]=useState()
  const [productData,setProduct]=useState()
  const [getinventory,setdataInventory]=useState([])
  const [wareHouse,setwareHouse]=useState([])
  const [newInve,setnewInve]=useState([])
  const [ware_id,setwareid]=useState("")
  const userEmail=JSON.parse(localStorage.getItem("user_token"))?.user?.email
  const [inventoryData,setinventoryData]=useState({
    item_no:"",
    title:"",
    barcode:"",
    purchase_price:"",
    retail_price:"",
    meta_data:"",
    tax_rate:"",
    category:"",
    batch_type:"",
    default_postal_service_id:"",
    packaging_group_id: "",
    weight_grams: "",
    weight_ounces: "",
    weight_pounds: "",
    weight_kilograms:"",
    height: '',
    width: '',
    depth: '',
    volume: '',
    category: "",
  })
  const [SkuChanal,setChanal]=useState([])
  const [extandPro,setextandPro]=useState([])
  const [inventory,setinventory]=useState({
    binrack: "",
    batch_no: "",
    status: "",
    sell_by: "",
    level: "",
    value: "",
    expires_on:"",
    priority:"",
    warehouse_id: ""
})
const [productIdentifier,setproductIdentifier]=useState([])
const [invSupplier,setinvSupplier]=useState([])
const [pricingDescription,setpricingDescription]=useState([])
const [titleDescription,settitleDescription]=useState([])
const [productDescription,setproductDescription]=useState([])
const [composition,setcomposition]=useState([])
console.log(pricingDescription,titleDescription,productDescription);

const [supplier,setsuplier]=useState()
const [selectedImages, setSelectedImages] =useState([]);
const Createimge=()=>{
 var  formData = new FormData();
  var pic = selectedImages.map((image, index) => {
    formData.append(`images`, image);
    console.log(image);
    var url = URL.createObjectURL(image);
    return(
    <>{index === 0 ?
      <div>
        <div style={{display:"flex",justifyContent:"space-between"}}>
        <p className='mx-1'>Mian image</p>
          <div style={{cursor:"pointer",fontWeight:"bold"}} onClick={()=>remove(index)}>x</div>
        </div>
         
        <div style={{ border: "1px solid lightgray", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }} className='main_img'>
          <img className='show_pic' src={url} />
        </div>
      </div>
      :
      <div>
        <div style={{display:"flex",justifyContent:"end"}}>
        {/* <p className='mx-1'>Mian image</p> */}
          <div style={{cursor:"pointer",fontWeight:"bold"}} onClick={()=>remove(index)}>x</div>
        </div>
      <div  style={{ border: "1px solid lightgray", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column",marginTop:"25px" }} className='main_img'>
        {/* <div onClick={()=>delimage(item.id)}>x</div> */}
        <img className='show_pic' src={url} />

      </div>
      </div>
    }
    </>
    )
  })
  return pic
}

const handleImageChange = (e) => {
  if(e?.target?.files){

    const files = Array.from(e.target.files);
    let images=selectedImages.concat(files)
    console.log(images);
    
    setSelectedImages(images);
    Createimge()
  }
  else{
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    setSelectedImages(files);
    Createimge()
  }
};
const remove=(id)=>{
  console.log(id);
  const data=selectedImages.filter((item,index)=>{
    if(index!==id){
      return item
    }
  })
  setSelectedImages(data)
  Createimge()
}
const [multipulSelct,setmultipulSelct]=useState([])
const base_url="https://wholesaleshopping.cn/linnworks/public/api/"
const imgBase_url="https://wholesaleshopping.cn/linnworks/public/"

// const base_url="http://192.168.1.14:8000/api/"
  const register = async(obj) => {
          fetch(`${base_url}register`,{
            method: "POST",
            headers: {
                'Content-Type': "application/json",
                "Accept": 'application/json',
            },
            body: JSON.stringify(obj)
        })
            .then(res => res.json())
            .then(resp => {
              if(resp.message==="Registration successful"){
                swal({
                  title: "Registration successful",
                  text: 'Go to login Page',
                  icon: "success",
                  // dangerMode: true,
                });
              }
              else{
                swal({
                  title: resp.message,
                  text: "something went wrong",
                  icon: "warning",
                  dangerMode: true,
                });
              }
            })
  }
  const getinventoryFun= async()=>{
      
 await fetch(`${base_url}prodetails`,{
    headers:{
      'Authorization': token,
    }
  })
      .then(res => res.json())
      .then(resp => {
        setdataInventory({
          product_type:"defult",
          columns: ["",'SKU', 'Title', 'Barcode Number','Retail Price', 'Purchase Price', 'Stock Level','In Open Orders', 'Available', 'Minimum Level','Tracked'],
          products:resp.single_products,
          variation:resp.variations
        });
      })
  }
  const login = async(obj) => {
    fetch(`${base_url}login`, {
      method: 'POST',
      // mode:"no-cors",
      headers: {
        'Content-Type': 'application/json',
         "Accept": 'application/json',   
      },
      body: JSON.stringify(obj)
    })
    .then(response => response.json())
    .then(data => {
      if(data?.message){
        swal({
          title: data?.message,
          text: 'User not found',
          icon: "warning",
          dangerMode: true,
        });
      }
      console.log('Success:', data);
      setLogin(data)
    })
  }
  const addCategory = async(obj) => {

    fetch(`${base_url}categories`,{
         method: "POST",
         headers: {
             'Content-Type': "application/json",
             "Accept": 'application/json',
             'Authorization': token,
         },
         body: JSON.stringify(obj)
     })
         .then(res => res.json())
         .then(resp => {
          console.log(resp);
          if(resp?.status){
            setallCetagore([...allCetagore,resp.category])
            swal({
              title: "Create Cetagory SuccessFully",
              text: 'successFully',
              icon: "success",
              // dangerMode: true,
            });
          }
          else{
            swal({
              title: resp?.message?resp?.message:resp.name[0],
              text: 'Warning',
              icon: "warning",
              dangerMode: true,
            });
          }
        
         })

 }
  const forget = async(obj) => {

    fetch(`${base_url}password/reset-link`,{
         method: "POST",
         headers: {
             'Content-Type': "application/json",
             "Accept": 'application/json',
             'Authorization': token,
         },
         body: JSON.stringify(obj)
     })
         .then(res => res.json())
         .then(resp => {
          console.log(resp);
          if(resp?.status){
            swal({
              title: resp?.status,
              text: 'Check the email',
              icon: "success",
              // dangerMode: true,
            });
          }
          else{
            swal({
              title: resp?.message,
              text: 'Warning',
              icon: "warning",
              dangerMode: true,
            });
          }
        
         })

 }
 const resetpassword = async(obj) => {
  // fetch(`http://192.168.10.12:8000/api/password/reset`,{
  //      method: "POST",
  //      headers: {
  //          'Content-Type': "application/json",
  //          "Accept": 'application/json',
  //          'Authorization': token,
  //      },
  //      body: JSON.stringify(obj)
  //  })
  //      .then(res => res.json())
  //      .then(resp => {
  //       console.log(resp);
  //       if(resp?.status){
  //         swal({
  //           title: resp?.status,
  //           text: 'Check the email',
  //           icon: "success",
  //           // dangerMode: true,
  //         });
  //       }
  //       else{
  //         swal({
  //           title: resp?.message,
  //           text: 'Warning',
  //           icon: "warning",
  //           dangerMode: true,
  //         });
  //       }
  //      })

}
 const datacetagory=()=>{
  
  fetch(`${base_url}categories`,{
    headers:{
      'Authorization': token,
    }
})
    .then(res => res.json())
    .then(resp => {
      setallCetagore(resp);

   
    })
 }
 const deletCategory=(id)=>{
  console.log(id);
  fetch(`${base_url}categories`,{
    method: "DELETE",
    headers: {
      'Content-Type': "application/json",
      "Accept": 'application/json',
      'Authorization': token,
  },
  body: JSON.stringify({
    category:id
  })
})
    .then(res => res.json())
    .then(resp => {
     console.log(resp);
     if(resp?.status){
      datacetagory()
       swal({
         title: 'Delete Successfully',
         text: 'Successfully',
         icon: "success",
         // dangerMode: true,
       });
     }
     else{
       swal({
         title: resp?.message,
         text: 'Warning',
         icon: "warning",
         dangerMode: true,
       });
     }
   
    })
 }
 const deletWarehouse=(id)=>{
  fetch(`${base_url}warehouse-delete`,{
    method: "DELETE",
    headers:{
     'Content-Type': "application/json",
      "Accept": 'application/json',
      'Authorization': token,
    },
    body:JSON.stringify({
      warehouses:id
    })
})
    .then(res => res.json())
    .then(resp => {
     console.log(resp);
     if(resp?.message=="Warehouses deleted successfully"){
      WareHouse()
       swal({
         title: 'Delete Successfully',
         text: 'Successfully',
         icon: "success",
         // dangerMode: true,
       });
     }
     else{
       swal({
         title: resp?.message,
         text: 'Warning',
         icon: "warning",
         dangerMode: true,
       });
     }
   
    })
 }
 const delsupplier=(id)=>{
  console.log(id);
  fetch(`${base_url}suppliers`,{
    method: "DELETE",
    headers:{
      'Content-Type': "application/json",
      "Accept": 'application/json',
      'Authorization': token,
    },
    body:JSON.stringify({
      supplier_ids:id
    })
    
})
 .then(res => res.json())
    .then(resp => {
     console.log(resp);
     if(resp){
      // setsuplier(newddata)
      getsuplier()
       swal({
         title: 'Delete Successfully',
         text: 'Successfully',
         icon: "success",
         // dangerMode: true,
       });
     }
     else{
       swal({
         title: resp?.message,
         text: 'Warning',
         icon: "warning",
         dangerMode: true,
       });
     }
   
    })
 }
 const editCategory=(obj,id)=>{
  fetch(`${base_url}categories/${id}?name=${obj}`,{
    method: "PUT",
     headers:{
      'Authorization': token,
    }
})
    .then(res => res.json())
    .then(resp => {
     console.log(resp);
     if(resp?.status){
      const newdata=allCetagore.filter((item)=>{
        if(item.id===id){
          item.name=obj
          return item
        }
        else{
          return item
        }
      })
      setallCetagore(newdata)
       swal({
         title: 'Update Successfully',
         text: 'Successfully',
         icon: "success",
         // dangerMode: true,
       });
     }
     else{
       swal({
         title: resp?.message,
         text: 'Warning',
         icon: "warning",
         dangerMode: true,
       });
     }
   
    })
 }
 const createProduct =(obj)=>{
  fetch(`${base_url}prodetails`,{
       method: "POST",
       headers: {
           'Content-Type': "application/json",
           "Accept": 'application/json',
           'Authorization': token,
       },
       body: JSON.stringify(obj)
   })
       .then(res => res.json())
       .then(resp => {
        console.log(resp);
       })
 }
 const productDetail=()=>{
  fetch(`${base_url}prodetails`,{
    headers:{
      'Authorization': token,
    }
  })
      .then(res => res.json())
      .then(resp => {
        setProduct(resp)
  
     
      })
 }
 const addinventory=()=>{
  
console.log(extandPro);
console.log(SkuChanal);
const data = new FormData();
  console.log(inventory.warehouse_id);
data.append('item_no', inventoryData.item_no);
data.append('warehouse_id', ware_id?ware_id:"");
data.append('title', inventoryData.title);
data.append('image', selectedImages.length?selectedImages[0]:"");
data.append('barcode', inventoryData.barcode);
data.append('purchase_price', inventoryData.purchase_price);
data.append('retail_price', inventoryData.retail_price);
data.append('meta_data', inventoryData.meta_data);
data.append('tax', inventoryData.tax_rate);
data.append('default_postal_service_id', inventoryData.default_postal_service_id);
data.append('packaging_group_id', inventoryData.packaging_group_id);
data.append('weight_grams', inventoryData.weight_grams);
data.append('weight_ounces', inventoryData.weight_ounces);
data.append('weight_pounds', inventoryData.weight_pounds);
data.append('weight_kilograms', inventoryData.weight_kilograms);
data.append('height', inventoryData.height);
data.append('width', inventoryData.width);
data.append('depth', inventoryData.depth);
data.append('volume', inventoryData.volume);
data.append('category', inventoryData.category);
if(selectedImages.length){
  selectedImages.map((image,idx) => {
   data.append(`pro_imgs[]`, image);
   
 })
}
else{
  data.append(`pro_imgs`, JSON.stringify([]));
}

const proCompositionJson = JSON.stringify(composition);
const proSkuChanal = JSON.stringify(SkuChanal);
const proinvSupplier = JSON.stringify(invSupplier);

data.append('product_compositions', proCompositionJson);
data.append('pro_imgs_url[url]', 'https://image.jpg');
data.append('channelSku', proSkuChanal);
data.append('pro_supplier', proinvSupplier);

const proextandPro = JSON.stringify(extandPro);
const proproductDescription = JSON.stringify(productDescription);
const proproduct_identifier = JSON.stringify(productIdentifier);
const protitleDescription = JSON.stringify(titleDescription);
const propricingDescription = JSON.stringify(pricingDescription);
const pronewInve1 = newInve[0]?.value==""?[]: newInve
const pronewInve = JSON.stringify(pronewInve1);
data.append('extended_properties', proextandPro);
data.append('pro_inventory', pronewInve);
data.append('product_identifier', proproduct_identifier);
data.append('pricingDescription',propricingDescription);
data.append('titleDescription', protitleDescription);
data.append('productDescription', proproductDescription);

 if(newInve[0]?.warehouse_id==""){
  swal({
    title: "warehouse select first",
    text: 'Warning',
    icon: "warning",
    dangerMode: true,
  });
 }
 else{
  fetch(`${base_url}prodetails`,{
    method: "POST",
    headers:{
     'Authorization': token,
   },
    body: data
})
    .then(res => res.json())
    .then(resp => {
     console.log(resp);
     swal({
       title: 'Created Successfully',
       text: 'Successfully',
       icon: "success",
       // dangerMode: true,
     });
     getinventoryFun()
     setinventoryData({
       item_no:"",
       title:"",
       barcode:"",
       purchase_price:"",
       retail_price:"",
       meta_data:"",
       tax_rate:"",
       category:"",
       batch_type:"",
       default_postal_service_id:"",
       packaging_group_id: "",
       weight_grams: "",
       weight_ounces: "",
       weight_pounds: "",
       weight_kilograms:"",
       height: '',
       width: '',
       depth: '',
       volume: '',
       category: "",
     })
    })
 }
 }
 const createWareHouse=(obj)=>{
  fetch(`${base_url}warehouse`,{
    method: "POST",
    headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
    },
    body: JSON.stringify(obj)
})
    .then(res => res.json())
    .then(resp => {
      console.log(resp);
      setwareHouse([...wareHouse,resp])
      swal({
        title: 'Created Successfully',
        text: 'Successfully',
        icon: "success",
        // dangerMode: true,
      });

setinventoryData({
        item_no:"",
        title:"",
        barcode:"",
        purchase_price:"",
        retail_price:"",
        meta_data:"",
        tax_rate:"",
        category:"",
        batch_type:"",
        default_postal_service_id:"",
        packaging_group_id: "",
        weight_grams: "",
        weight_ounces: "",
        weight_pounds: "",
        weight_kilograms:"",
        height: '',
        width: '',
        depth: '',
        volume: '',
        category: "",
      })
    })
 }
 const WareHouse=()=>{
  fetch(`${base_url}warehouse`,{
    headers:{
      'Authorization': token,
    }
  })
    .then(res => res.json())
    .then(resp => {
  console.log(resp);
  setwareHouse(resp)

    })
 }
 const editWareHouse=(id,obj)=>{
  fetch(`${base_url}warehouse/${id}`,{
    method: "PUT",
    headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
    },
    body: JSON.stringify(obj)
})
    .then(res => res.json())
    .then(resp => {
  console.log(resp);
  if(resp){
     swal({
       title: 'Update Successfully',
       text: 'Successfully',
       icon: "success",
       // dangerMode: true,
     });
   }
   else{
     swal({
       title: resp?.message,
       text: 'Warning',
       icon: "warning",
       dangerMode: true,
     });
   }
  var array=wareHouse.filter((item)=>{
    if(item.id!==resp.id){
      return item
    }
  })
  array.push(resp)
  setwareHouse(array)
    })
  }
  const getsuplier=()=>{
    fetch(`${base_url}suppliers`,{
      headers:{
        'Authorization': token,
      }
    })
    .then(res => res.json())
    .then(resp => {
  console.log(resp);
  setsuplier(resp)

    })
  }
  const createSuplier=(obj)=>{
    fetch(`${base_url}suppliers`,{
      method: "POST",
      headers: {
          'Content-Type': "application/json",
          "Accept": 'application/json',
          'Authorization': token,
      },
      body: JSON.stringify(obj)
  })
      .then(res => res.json())
      .then(resp => {
        console.log(resp);
        setsuplier([...supplier,resp])
        if(resp.error){
          swal({
            title:"Something went wrong" ,
            text: "something went wrong",
            icon: "warning",
            dangerMode: true,
          });
          swal({
            title: "",
            text: 'Supplier Create successfully',
            icon: "success",
            // dangerMode: true,
          });
          
        }
        // else{
          swal({
            title: "Created successfully",
            text: 'Supplier Create successfully',
            icon: "success",
            // dangerMode: true,
          });
        // }
      })
  }
  const [currencyList,setcurrencyList]=useState([])
  const currency=()=>{
    fetch(`${base_url}currencies`,{
      headers:{
        'Authorization': token,
      }
    })
    .then(res => res.json())
    .then(resp => {
  console.log(resp);
  setcurrencyList(resp)

    })
  }
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(null);

  const openTab = (component, name) => {
    const newTab = { component, name, id: Date.now() };
    setTabs((prevTabs) => [...prevTabs, newTab]);
    setActiveTab(newTab.id);
  };

  const closeTab = (id) => {
    setTabs((prevTabs) => prevTabs.filter((tab) => tab.id !== id));
    if (activeTab === id) {
      const newActiveTab = tabs.find((tab) => tab.id !== id);
      setActiveTab(newActiveTab ? newActiveTab.id : null);
    }
  };
  const [transferData,setTransferData]=useState([])
  const wareTransferData=(obj)=>{
    fetch(`${base_url}product-count-by-warehouse/${obj?.ship_from}/${obj?.ship_destination}`,{
      headers:{
        'Authorization': token,
      }
    })
    .then(res => res.json())
    .then(resp => {
      setTransferData(resp?.product_details)
  

    })
  }
  const createTranfer=(obj)=>{
    fetch(`${base_url}transfers`,{
      method: "POST",
      headers: {
          'Content-Type': "application/json",
          "Accept": 'application/json',
          'Authorization': token,
      },
      body: JSON.stringify(obj)
  })
      .then(res => res.json())
      .then(resp => {
       console.log(resp);
       if(resp?.transfer_id){
       
         swal({
           title: "Transfer Create Successfully",
           text: 'successFully',
           icon: "success",
           // dangerMode: true,
         });
       }
       else{
         swal({
           title: "Something went Wrong",
           text: 'Warning',
           icon: "warning",
           dangerMode: true,
         });
       }
     
      })
  }
  const[getTrasferData,setgetTrasferdata]=useState([])
  const getTrasfer=()=>{
    fetch(`${base_url}get-transfers`,{
      headers:{
        'Authorization': token,
      }
    })
    .then(res => res.json())
    .then(resp => {
  console.log(resp);
  setgetTrasferdata(resp)

    })
  }
  const filter=(id)=>{
    if(id==0){
      fetch(`${base_url}prodetails`,{
        headers:{
          'Authorization': token,
        }
      })
          .then(res => res.json())
          .then(resp => {
  
 
            setdataInventory({
              product_type:"defult",
              columns: ["",'SKU', 'Title', 'Barcode Number','Retail Price', 'Purchase Price', 'Stock Level','In Open Orders', 'Available', 'Minimum Level','Tracked'],
              products:resp.single_products,
              variation:resp.variations
            });
      
         
          })
    }
    else{
      fetch(`${base_url}prodetails`,{
        headers:{
          'Authorization': token,
        }
      })
          .then(res => res.json())
          .then(resp => {
  
           const newdata=resp.single_products.filter((item)=>{
        if(item.warehouse_id==id){
          return item
        }
      })
      setdataInventory({
        product_type:"defult",
        columns: ["",'SKU', 'Title', 'Barcode Number','Retail Price', 'Purchase Price', 'Stock Level','In Open Orders', 'Available', 'Minimum Level','Tracked'],
        products:newdata,
        variation:resp.variations
      });
          })
    }
  
    
  }
  // const ex_token='Bearer 50|pG76gdB7eiHBkv03qtxRVOBfeBIXT7x9M22PJ1f5'
  const [variationData,setVariation]=useState([])
  // const ex_baseUrl="http://192.168.1.6:8000/api/"
  const getVariation=()=>{
    fetch(`${base_url}variations`,{
      headers: {
        'Authorization': token,
    }
    })
    .then(res => res.json())
    .then(resp => {
  console.log(resp);
  setVariation(resp)

})
  }
  const editVariation=(obj)=>{
    console.log(obj);
    fetch(`${base_url}variations/${obj.id}`,{
      method: "PUT",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
    },
    body: JSON.stringify(obj)
    })
    .then(res => res.json())
    .then(resp => {
  console.log(resp);
  if(resp.message=="Variation and products updated successfully"){

    swal({
      title: "Edit Variation SuccessFully",
      text: 'successFully',
      icon: "success",
      // dangerMode: true,
    });
    getVariation()
  }
  else{
    swal({
      title: resp?.message,
      text: 'Warning',
      icon: "warning",
      dangerMode: true,
    });
  }
  // setVariation(resp)

})
  }
  const [searchValue,setSeacrch]=useState()
  const search=(value)=>{
    if(value){

      fetch(`${base_url}variations/search-products?query=${value}`,{
        headers:{
          'Authorization':token,
        }
      })
      .then(res => res.json())
      .then(resp => {
      console.log(resp);
      setSeacrch(resp)
      
      })
    }

  }
  const createVraiation=(obj)=>{
    fetch(`${base_url}variations`,{
      method: "POST",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
    },
    body: JSON.stringify(obj)
    })
    .then(res => res.json())
    .then(resp => {
  console.log(resp);
  // setVariation(resp)
  swal({
    title: "Create Variation Successfully",
    text: 'successFully',
    icon: "success",
    // dangerMode: true,
  });
  getVariation()

})
  }

  const delVariation=(id)=>{
    if(id){

      fetch(`${base_url}variations`,{
        method: "DELETE",
        headers:{
          'Content-Type': "application/json",
          "Accept": 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({ids:id})
    })
        .then(res => res.json())
        .then(resp => {
         console.log(resp);
         if(resp?.message=='Variations deleted successfully'){
          getVariation()
           swal({
             title: 'Delete Successfully',
             text: 'Successfully',
             icon: "success",
             // dangerMode: true,
           });
         }
         else{
           swal({
             title: resp?.message,
             text: 'Warning',
             icon: "warning",
             dangerMode: true,
           });
         }
       
        })
    }
    else{
      swal({
        title: "Variation Not Selected",
        text: 'Warning',
        icon: "warning",
        dangerMode: true,
      });
    }
  }
  const createPo=(obj)=>{
    fetch(`${base_url}purchase-orders`,{
      method: "POST",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
    },
    body: JSON.stringify(obj)
    })
    .then(res => res.json())
    .then(resp => {
  console.log(resp);
  // setVariation(resp)
  swal({
    title: "Create purchase orders SuccessFully",
    text: 'successFully',
    icon: "success",
    // dangerMode: true,
  });
  getVariation()

})
  }
  const [sku,setsku]=useState()
  const getSku=()=>{
    console.log("resp");

    fetch(`${base_url}generate-sku`,{
      headers:{
        'Authorization':token,
      }
    })
    .then(res => res.json())
    .then(resp => {
    console.log(resp);
    setsku(resp)
    
    })
  }
  const searchBy=(value)=>{
    console.log(value);
    // api/products/search?query=t
    fetch(`${base_url}products/search?query=${value}`,{
      headers:{
        'Authorization':token,
      }
    })
    .then(res => res.json())
    .then(resp => {
    console.log(resp);
    const variation=getinventory.variations
    setdataInventory({
      product_type:"defult",
      columns: ["",'SKU', 'Title', 'Barcode Number','Retail Price', 'Purchase Price', 'Stock Level','In Open Orders', 'Available', 'Minimum Level','Tracked'],
      products:resp,
      variation:variation
    });
    
    })
  }
  const [poData,setPOdata]=useState([])
  const getPO=()=>{
    fetch(`${base_url}purchase-orders`,{
      headers:{
        'Authorization':token,
      }
    })
    .then(res => res.json())
    .then(resp => {
    console.log(resp);
    setPOdata(resp)
    
    })
  }
  const deletePO=(id)=>{
    if(id){
      fetch(`${base_url}purchase-orders/${id}`,{
        method: "DELETE",
        headers:{
          'Authorization': token,
        }
    })
        .then(res => res.json())
        .then(resp => {
         console.log(resp);
         if(resp){
     
          // setallCetagore(newddata)
           swal({
             title: 'Delete Successfully',
             text: 'Successfully',
             icon: "success",
             // dangerMode: true,
           });
         }
         else{
           swal({
             title: resp?.message,
             text: 'Warning',
             icon: "warning",
             dangerMode: true,
           });
         }
       
        })
    }else{
      swal({
        title: "PO Not Found",
        text: 'Warning',
        icon: "warning",
        dangerMode: true,
      });
    }

   }
   const editPo=(obj)=>{
    fetch(`${base_url}purchase-orders/${obj.id}`,{
      method: "PUT",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
    },
    body: JSON.stringify(obj)
    })
    .then(res => res.json())
    .then(resp => {
  console.log(resp);
  // setVariation(resp)
  getPO()
  swal({
    title: "Edit PO SuccessFully",
    text: 'successFully',
    icon: "success",
    // dangerMode: true,
  });

})
  }
   const changePOstatus=(obj)=>{
    fetch(`${base_url}purchase-order/${obj.id}/update-status`,{
      method: "PUT",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
    },
    body: JSON.stringify({
      status: obj.status
    })
    })
    .then(res => res.json())
    .then(resp => {
  console.log(resp);
  // setVariation(resp)
  // getPO()
  if(resp?.message=="Status updated successfully!"){
    swal({
      title: "Status updated successfully!",
      text: 'successFully',
      icon: "success",
      // dangerMode: true,
    });
    getPObyId(obj.id)
    
  }

})
  }
  const [singelPo,setSingalPO]=useState()
  const getPObyId=(id)=>{
    fetch(`${base_url}purchase-orders/${id}`,{
 
      headers: {
     
        'Authorization': token,
    },
    })
    .then(res => res.json())
    .then(resp => {
  console.log(resp);
  setSingalPO(resp)
  // setVariation(resp)
  // getPO()
  // if(resp?.message=="Status updated successfully!"){
  //   swal({
  //     title: "Status updated successfully!",
  //     text: 'successFully',
  //     icon: "success",
  //     // dangerMode: true,
  //   });
    
  // }

})
  }
  const prodelete=(id)=>{
    console.log(id);
    if(id){
      fetch(`${base_url}products/multiple-delete`,{
        method: "DELETE",
        headers:{
          'Content-Type': "application/json",
          "Accept": 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          product_ids:id
        })
    })
        .then(res => res.json())
        .then(resp => {
         console.log(resp);
         if(resp.message=="Products deleted successfully."){
     
          // setallCetagore(newddata)
           swal({
             title: resp.message,
             text: 'Successfully',
             icon: "success",
             // dangerMode: true,
           });
           getinventoryFun()
         }
         else if(resp.message){
           swal({
            title: 'Transfer Associated with it.',
            text:resp.message,
             icon: "warning",
             dangerMode: true,
           });
         }
       
        })
    }else{
      swal({
        title: "PO Not Found",
        text: 'Warning',
        icon: "warning",
        dangerMode: true,
      });
    }
  }
  const getByCategory=(id)=>{
    if(id==0){
      fetch(`${base_url}prodetails`,{
        headers:{
          'Authorization': token,
        }
      })
          .then(res => res.json())
          .then(resp => {
  
 
            setdataInventory({
              product_type:"defult",
              columns: ["",'SKU', 'Title', 'Barcode Number','Retail Price', 'Purchase Price', 'Stock Level','In Open Orders', 'Available', 'Minimum Level','Tracked'],
              products:resp.single_products,
              variation:resp.variations
            });
      
         
          })
    }
    else{
      fetch(`${base_url}prodetails`,{
        headers:{
          'Authorization': token,
        }
      })
          .then(res => res.json())
          .then(resp => {
  // console.log(resp);
           const newdata=resp.single_products.filter((item)=>{
        if(item.category==id){
          return item
        }
      })
      setdataInventory({
        product_type:"defult",
        columns: ["",'SKU', 'Title', 'Barcode Number','Retail Price', 'Purchase Price', 'Stock Level','In Open Orders', 'Available', 'Minimum Level','Tracked'],
        products:newdata,
        variation:resp.variations
      });
          })
    }
  

  }
  const [source,setsource]=useState()
  const getsource=()=>{
    fetch(`${base_url}sources`,{
      headers:{
        'Authorization':token,
      }
    })
    .then(res => res.json())
    .then(resp => {
    console.log(resp);
    setsource(resp)
    
    })

  }
  const [subsource,setsubsource]=useState()
  const getsubsource=()=>{
    fetch(`${base_url}subsources`,{
      headers:{
        'Authorization':token,
      }
    })
    .then(res => res.json())
    .then(resp => {
    console.log(resp);
    setsubsource(resp)
    
    })

  }
  const expoort=(obj)=>{
    let object=  {
      delimiter: obj.delimiter,
      encoding: obj.encoding,
      exportType: obj.exportType,
      selectedColumns:obj.selectedColumns,
      productIds:multipulSelct
    }
    console.log(object);
    fetch(`${base_url}export-csv`,{
      method: "POST",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
    },
    body: JSON.stringify(object)
    })
    .then(res => res.blob())
    .then(resp => {
      const csvUrl = URL.createObjectURL(resp);

      const link = document.createElement('a');
      link.href = csvUrl;
      link.download = 'data.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  // setVariation(resp)
  // getVariation()

})
  }
  const [allScrabcategory,setScrabCategory]=useState([])
  const addScrabCategory = async(obj) => {

    fetch(`${base_url}scrap-categories`,{
         method: "POST",
         headers: {
             'Content-Type': "application/json",
             "Accept": 'application/json',
             'Authorization': token,
         },
         body: JSON.stringify(obj)
     })
         .then(res => res.json())
         .then(resp => {
          console.log(resp);
          allscrabcategory()
          if(resp){
            swal({
              title: "Create Cetagory SuccessFully",
              text: 'successFully',
              icon: "success",
              // dangerMode: true,
            });
          }
          else{
            swal({
              title: resp?.message?resp?.message:resp.name[0],
              text: 'Warning',
              icon: "warning",
              dangerMode: true,
            });
          }
        
         })

 }
 const allscrabcategory=()=>{
  fetch(`${base_url}scrap-categories`,{
    
    headers: {
    
        'Authorization': token,
    },
   
})
    .then(res => res.json())
    .then(resp => {
     console.log(resp);
    
       setScrabCategory(resp)
    })
 }
 const deletScrabCategory=(id)=>{
  fetch(`${base_url}scrap-categories/${id}`,{
    method: "DELETE",
    headers:{
      'Authorization': token,
    }
})
    .then(res => res.json())
    .then(resp => {
     console.log(resp);
     if(resp){
         const newddata=allScrabcategory.filter((item)=>{
             if(item.id!==id){
                 return item
                }
            })
            // eslint-disable-next-line
            setScrabCategory(newddata)
       swal({
         title: 'Delete Successfully',
         text: 'Successfully',
         icon: "success",
         // dangerMode: true,
       });
     }
     else{
       swal({
         title: resp?.message,
         text: 'Warning',
         icon: "warning",
         dangerMode: true,
       });
     }
   
    })
 }
 const [scrabItem,setScrabItem]=useState()
 const scrabFunction=()=>{
  fetch(`${base_url}scrap-items`,{
    
    headers: {
        'Authorization': token,
    },
   
})
    .then(res => res.json())
    .then(resp => {
     console.log(resp);
    
     setScrabItem(resp)
    })
 }
 const createscrabFunction=(obj)=>{
  fetch(`${base_url}scrap-items`,{
    method: "POST",
    headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
    },
    body: JSON.stringify(obj)
   
})
    .then(res => res.json())
    .then(resp => {
     console.log(resp);
    
     scrabFunction()
     swal({
      title: "Create Scrab Item SuccessFully",
      text: 'successFully',
      icon: "success",
      // dangerMode: true,
    });
    })
 }
 const stock=(obj)=>{
  fetch(`${base_url}update-stock-level`,{
    method: "POST",
    headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
    },
    body: JSON.stringify(obj)
   
})
    .then(res => res.json())
    .then(resp => {
     console.log(resp);
     if(resp){

       swal({
        title: "Create Cetagory SuccessFully",
        text: 'successFully',
        icon: "success",
        // dangerMode: true,
      });
     }
    })
 }
 const createView=(obj)=>{
  fetch(`${base_url}saveViewAndFetchProducts`,{
    method: "POST",
    headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
    },
    body: JSON.stringify(obj)
   
})
    .then(res => res.json())
    .then(resp => {
     console.log(resp);
     if(resp){
      getallView()
       swal({
        title: "Create View SuccessFully",
        text: 'successFully',
        icon: "success",
        // dangerMode: true,
      });
     }
    })
 }
 const [allview,setallvew]=useState()
 const getallView=()=>{
  fetch(`${base_url}user-views`,{
    headers: {
        'Authorization': token,
    },
})
    .then(res => res.json())
    .then(resp => {
     console.log(resp);
     setallvew(resp)
    })
 }
 const searchPO=(obj)=>{
  console.log(obj);
  fetch(`${base_url}purchase-orders/search?warehouse_id=${obj.warehouse}&supplier_id=${obj.supplier_id}&start_date=2023-06-14&end_date=${obj.e_date}&options[]=${obj.redf_num}&search_term=${obj.text}`,{
    headers: {
        'Authorization': token,
    },
})
    .then(res => res.json())
    .then(resp => {
     console.log(resp);
     setPOdata(resp)
    //  setallvew(resp)
    })
 }
 const delView=(id)=>{
  fetch(`${base_url}user-views/${id}`,{
    method: "DELETE",
    headers:{
      'Authorization': token,
    }
})
    .then(res => res.json())
    .then(resp => {
     console.log(resp);
     if(resp){
        getallView()
        getinventoryFun()
       swal({
         title: 'Delete Successfully',
         text: 'Successfully',
         icon: "success",
         // dangerMode: true,
       });
     }
     else{
       swal({
         title: resp?.message,
         text: 'Warning',
         icon: "warning",
         dangerMode: true,
       });
     }
   
    })
 }
 let column=['SKU',
  'Title', 
  'Barcode Number',
  'Retail Price', 
  'Purchase Price', 
  'Stock Level',
  'In Open Orders', 
  'Available', 
  'Minimum Level',
  'Tracked',
  "Batch Number",
    "Scan Required",
    "Batch Type",
    "Bin Rack",
    "Category",
    "Created Date",
    "Daily Average Consumption",
    "Default Supplier",
    "Depth",
    "Width",
    "Height",
    "Just In Time",
    "Modified Date",
    "Primary Image",
    "Serial Number Scan Required",
    "Stock Value",
    "Variation Group Name",
    "Weight",
    "Width"
]
//  const [viewData,setViewData]=useState()
 const getViewId=(id)=>{
  console.log(id );
  fetch(`${base_url}saveViewAndFetchProducts`,{
    method: "POST",
    headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
    },
    body: JSON.stringify({
      view_id:id
    })

  })
  .then(res => res.json())
  .then(resp => {
console.log(resp);
// setVariation(resp)
let colu=[]
colu=JSON.parse(resp?.view?.columns)
var sorted=column.filter((item)=>{
 return colu.includes(item)
})
sorted.unshift(" ")
console.log(colu);
setdataInventory({
  columns:sorted,
  product_type:resp.view.product_type,
  products:resp.products});


})
 }
 const viewEdit=(obj,id)=>{
  fetch(`${base_url}user-views/${id}`,{
    method: "PUT",
    headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
    },
    body: JSON.stringify(obj)

  })
  .then(res => res.json())
  .then(resp => {
console.log(resp);
// setVariation(resp)

getallView()
swal({
  title: 'Update Successfully',
  text: 'Successfully',
  icon: "success",
  // dangerMode: true,
});
})
 }
 const editData=(obj)=>{
  fetch(`${base_url}prodetails/${obj.id}`,{
    method: "PUT",
    headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
    },
    body: JSON.stringify(obj)

  })
  .then(res => res.json())
  .then(resp => {
console.log(resp);
getinventoryFun()
})
if(selectedImages.length>0){
// console.log("ok");

  const data = new FormData();

  selectedImages.map((image,idx) => {
    data.append(`pro_imgs[]`, image);
    
  })
 
const image = JSON.stringify(obj?.images);
    data.append(`pro_imgs[]`, image);
  data.append(`product_id`, obj.id)
  fetch(`${base_url}update-image/${obj.id}`,{
    method: "POST",
    headers: {
        
        'Authorization': token,
    },
    body: data

  })
  .then(res => res.json())
  .then(resp => {
console.log(resp);
getinventoryFun()
setSelectedImages([])
})
}
 }
 const createnote=(obj)=>{
  fetch(`${base_url}ponotes`,{
    method: "POST",
    headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
    },
    body: JSON.stringify(obj)

  })
  .then(res => res.json())
  .then(resp => {
console.log(resp);
getPObyId(obj.po_id)
// getinventoryFun()
})
 }
 const createExtantPO=(obj)=>{
  console.log(obj);
  const data={
    name:obj[0]?.name,
    date:obj[0]?.date,
    po_id:obj[0]?.po_id,
    value:obj[0]?.value,
    vendor_id: obj[0]?.vendor_id,
    email:obj[0]?.email,
    time:obj[0]?.time

  }
  console.log(data);
  fetch(`${base_url}purchase-orders-extend`,{
    method: "POST",
    headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
    },
    body: JSON.stringify(data)

  })
  .then(res => res.json())
  .then(resp => {
console.log(resp);
getPObyId(data.po_id)
// getinventoryFun()
})
 }
 const archive=(id)=>{
  fetch(`${base_url}prodetails/${id}/archive`,{
    method: "PUT",
    headers: {
      
        'Authorization': token,
    },
    
  
  })
  .then(res => res.json())
  .then(resp => {
  console.log(resp);
  // getPObyId(obj.po_id)
  getinventoryFun()
  })
 }
 const duplicate=(obj)=>{
  fetch(`${base_url}products/duplicate`,{
    method: "POST",
    headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
    },
    body: JSON.stringify(obj)

  })
  .then(res => res.json())
  .then(resp => {
  console.log(resp);
 
  getinventoryFun()
  })
 }
 const [allTransfer,setallTransfer]=useState()
 const getTransferDetail=(id)=>{
  console.log(id);
  fetch(`${base_url}transfers/${id}`,{
    headers: {
        'Authorization': token,
    },
})
    .then(res => res.json())
    .then(resp => {
     console.log(resp);
     setallTransfer(resp)
    })

 }
 const getallTransfer=(id)=>{
  console.log(id);
  if(id==0){
    getTrasfer()
  }
  else{
    fetch(`${base_url}get-transfers?warehouse_id=${id}`,{
      
      headers: {
      
          'Authorization': token,
      },
     
  })
      .then(res => res.json())
      .then(resp => {
       console.log(resp);
      
       setgetTrasferdata(resp)
      })
  }
   }
   const transferStatus=(obj,status)=>{
    let array =[]
    // let data=obj.products
    let newobj={}
    console.log(obj);
    obj.products?.filter((item)=>{
      var newdata={}
      if(status=="receiving"){
        newdata={
          product_id:item?.product?.id,
          received_quantity:parseInt(item?.quantity_in_transfer)
        }
        array.push(newdata) 
      }
      else if(status=="in transit"){
        newdata={
          product_id:item?.product?.id,
          quantity:parseInt(item?.quantity_in_transfer)
        }
        array.push(newdata) 
      }
      else if(status=="received"){
        newdata={
          product_id:item?.product?.id,
          received_quantity:parseInt(item?.quantity_in_transfer)
        }
        array.push(newdata) 
      }
    })
    newobj={
      transfer_id:obj?.transfer_id,
      products: array,
      status:status,
      to_warehouse_id: obj?.to_warehouse_id,
      from_warehouse_id: obj?.from_warehouse_id,
    }
    fetch(`${base_url}transfers/status`,{
      method: "POST",
      headers: {
          'Content-Type': "application/json",
          "Accept": 'application/json',
          'Authorization': token,
      },
      body: JSON.stringify(newobj)
  })
      .then(res => res.json())
      .then(resp => {
       console.log(resp);
      //  setallTransfer(resp)
      swal({
        title: "Transfer Status Updated Successfully",
        text: 'successFully',
        icon: "success",
        // dangerMode: true,
      });
      })
   }
   const getdatabyWareId=(obj)=>{
    fetch(`${base_url}transfer/details`,{
      method: "POST",
      headers: {
          'Content-Type': "application/json",
          "Accept": 'application/json',
          'Authorization': token,
      },
      body: JSON.stringify(obj)
  })
      .then(res => res.json())
      .then(resp => {
       console.log(resp);
      
       setallTransfer(resp)
    
      })
   }
   const trackedFun = async(obj) => {

    fetch(`${base_url}product/setTracked`,{
         method: "POST",
         headers: {
             'Content-Type': "application/json",
             "Accept": 'application/json',
             'Authorization': token,
         },
         body: JSON.stringify(obj)
     })
         .then(res => res.json())
         .then(resp => {
          console.log(resp);
          getinventoryFun()
          // if(resp?.status){
          //   swal({
          //     title: resp?.status,
          //     text: 'Check the email',
          //     icon: "success",
          //     // dangerMode: true,
          //   });
          // }
          // else{
          //   swal({
          //     title: resp?.message,
          //     text: 'Warning',
          //     icon: "warning",
          //     dangerMode: true,
          //   });
          // }
        
         })

 }
 const editsupplier =(obj)=>{
  fetch(`${base_url}suppliers/${obj.id}`,{
    method: "PUT",
    headers: {
      'Content-Type': "application/json",
      "Accept": 'application/json',
        'Authorization': token,
    },
    body:JSON.stringify(obj)
  
  })
  .then(res => res.json())
  .then(resp => {
  console.log(resp);
  // getPObyId(obj.po_id)
  getsuplier()
  swal({
    title: "Supplier Updated Successfully",
    text: 'successFully',
    icon: "success",
    // dangerMode: true,
  });
  })
 }
 const delPONote=(id,poID)=>{
  fetch(`${base_url}ponotes/${id}`,{
    method:"DELETE",
    headers:{
      'Authorization': token,
    }
  })
  .then(res => res.json())
  .then(resp => {
  console.log(resp);
  // getPObyId(obj.po_id)
  getPObyId(poID)
  
  })
 }
  return (
    <StateContext.Provider
      value={{
        getdatabyWareId,
        archive,
        duplicate,
        createnote,
        createExtantPO,
        show,
        setshow,
        register,login,
        loginData,
        changePOstatus,
        forget,
        resetpassword,
        addCategory,
        datacetagory,
        allCetagore,
        deletCategory,
        editCategory,
        createProduct,
        productDetail,
        productData,
        setinventoryData,
        inventoryData,
        SkuChanal,
        setChanal,
        setextandPro,
        extandPro,
        setinventory,
        inventory,
        setproductIdentifier,
        productIdentifier,
        addinventory,
        getinventoryFun,
        getinventory,
        createWareHouse,
        wareHouse,
        WareHouse,
        editWareHouse,
        setwareid,
        handleImageChange,
        remove,
        Createimge,
        getsuplier,
        createSuplier,
        supplier,
        currency,
        currencyList,
        tabs,
        openTab,
        closeTab,
        activeTab,
        wareTransferData,
        transferData,
        createTranfer,
        getTrasfer,
        getTrasferData,
        search,
        deletWarehouse,
        delsupplier,
        filter,
        getVariation,
        variationData,
        editVariation,
        setLogin,
        searchValue,
        createVraiation,
        delVariation,
        createPo,
        getSku,
        sku,
        setdataInventory,
        invSupplier,setinvSupplier,
        searchBy,
        poData,
        getPO,
        deletePO,
        editPo,
        prodelete,
        getByCategory,
        pricingDescription,
        setpricingDescription,
        titleDescription,
        settitleDescription,
        productDescription,
        setproductDescription,
        getsource,
        source,
        getsubsource,
        subsource,
        multipulSelct,setmultipulSelct,
        expoort,
        userEmail,
        addScrabCategory,
        allscrabcategory,
        allScrabcategory,
        deletScrabCategory,
        scrabFunction,
        scrabItem,
        createscrabFunction,
        stock,
        createView ,
        allview,
        getallView,
        delView,
        getViewId,
        viewEdit,
        editData,
        setSeacrch,
        searchPO,
        getPObyId,
        singelPo,
        token,
        setSelectedImages,
        getallTransfer,
        allTransfer,
        getTransferDetail,
        transferStatus,
        base_url,
        setcomposition,
        trackedFun,
        editsupplier,
        delPONote,
        ware_id,
        newInve,
        setnewInve,
        imgBase_url
     
      }}
    >
      {children}
    </StateContext.Provider>
  );
};
export const useStateContext = () => useContext(StateContext);