import React, { useEffect, useState } from 'react'
import { useStateContext } from '../Context/ContextProvider';
import { RiDeleteBin5Line } from 'react-icons/ri'
import { FiEdit, FiSave} from 'react-icons/fi';
import { Add, Close } from '@mui/icons-material';
import swal from 'sweetalert';
function Supplier() {
  const {getsuplier,delsupplier,createSuplier,supplier, currency,editsupplier,
    currencyList}=useStateContext()
  console.log(supplier)
  const [itemId,setitem]=useState()
  const [data,setdata]=useState({
    supplier_name:"",
    contact_name:"",
    address:"",
    alternative_add:"",
    city_town:"",
    region:"",
    country:"",
    contact_telephone:"",
    secondary_telephone:"",
    email:"",
    currency_id:"2",
    fax_number:"",
    webpage:"",
  })
  console.log(data);
  useEffect(()=>{
    getsuplier()
    currency()
  },[])
  const create=()=>{
    if(data && !same){
      createSuplier(data)
      setdata({
        supplier_name:"",
        contact_name:"",
        address:"",
        alternative_add:"",
        city_town:"",
        region:"",
        country:"",
        contact_telephone:"",
        secondary_telephone:"",
        email:"",
        currency_id:"2",
        fax_number:"",
        webpage:"",
      })
    setsame(false)

    }
    else{
      swal({
        title: "Supplier is already exist",
        text: "change the supplier name",
        icon: "warning",
        dangerMode: true,
      });
    setsame(false)
    }

  }
  const [multipulSelct,setmultipulSelct]=useState([])
  const [select,setselect]=useState([])
  const getselect = (id, event) => {
    if (event.ctrlKey || event.metaKey) {

      setselect([...select, id]);
      setmultipulSelct([...multipulSelct, id])
    } else {
      setselect([id]);
      setmultipulSelct([id])
    }
  }
  const isRowSelected = (id) => select.includes(id);
  const [same,setsame]=useState(false)
  return (
    <div className='category'>
      <div className='main_supplier'>
    
    <div className='row'>
      <div className='col-9'></div>
      <div className='col-3'>

  {/* Button trigger modal */}
  <button onClick={()=>
    setdata({
      supplier_name:"",
      contact_name:"",
      address:"",
      alternative_add:"",
      city_town:"",
      region:"",
      country:"",
      contact_telephone:"",
      secondary_telephone:"",
      email:"",
      currency_id:"2",
      fax_number:"",
      webpage:"",
    })
  } type="button" data-toggle="modal" data-target="#exampleModalCenter">
  <Add style={{fontSize:"14px"}}/> Add New
</button>
  {/* Modal */}
  <div className="modal fade bd-example-modal-lg my-5" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
<div className="modal-dialog  modal-lg modal-dialog-centered" role="document">
  <div className="modal-content">
    <div className="modal-header">
      <h5 className="modal-title" id="exampleModalCenterTitle">Create Supplier</h5>
      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true" ><Close style={{fontSize:"25px",fontWeight:"bold",color:"lightgray"}}/></span>
      </button>
    </div>
    <div className="modal-body">
<div className='container'>

    <div className='postage_defination'>
            <h6>Name</h6>
            <div className='row'>
              <div className='col-3'>
                <label>Supplier Name:</label>
              </div>
              <div className='col-9'>
              <input type='text' value={data.supplier_name} onChange={(e)=>{
                supplier?.map((item)=>{
                  if(item.supplier_name==e.target.value){
                    setsame(true)

                    setdata({...data,supplier_name:e.target.value})
                  }
                  else{
                    setdata({...data,supplier_name:e.target.value})
                    setsame(false)
                  }
                })
              }} placeholder='Supplier Name' style={{width:"90%",border:`${same?"1px solid red":"1px solid lightgray"}`}}/>
              {same?<p style={{color:"red",padding:"0"}}>This Supplier is already exist</p>:""}
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
              <label>Contact Name:</label>

              </div>
              <div className='col-9'>
              <input type='text' value={data.contact_name}   onChange={(e)=>setdata({...data,contact_name:e.target.value})}  placeholder='Contact Name' style={{width:"90%"}}/>

              </div>
            </div>
           

      
        </div>
        <div className='postage_defination'>
            <h6>Contact Data</h6>
            <div className='row'>
              <div className='col-3'>
                    <label>Address:</label>
              </div>
              <div className='col-9'>
              <input type='text'value={data.address}   onChange={(e)=>setdata({...data,address:e.target.value})}  placeholder='Address' style={{width:"90%"}}/>

              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
              <label>Alternative Address:</label>

              </div>
              <div className='col-9'>
              <input type='text' value={data.alternative_add}   onChange={(e)=>setdata({...data,alternative_add:e.target.value})}  placeholder='Alternative Address' style={{width:"90%"}}/>

              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
              <label>City / Town:</label>

              </div>
              <div className='col-9'>
              <input type='text' value={data.city_town}   onChange={(e)=>setdata({...data,city_town:e.target.value})}  placeholder='City / Town' style={{width:"90%"}}/>

              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
              <label>Region:</label>

              </div>
              <div className='col-9'>
              <input type='text' value={data.region}   onChange={(e)=>setdata({...data,region:e.target.value})}  placeholder='Region' style={{width:"90%"}}/>

              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
              <label>Country:</label>

              </div>
              <div className='col-9'>
              <input type='text' value={data.country}  onChange={(e)=>setdata({...data,country:e.target.value})} placeholder='Country' style={{width:"90%"}}/>

              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
              <label>Post Code:</label>

              </div>
              <div className='col-9'>
              <input type='text' value={data.post_code}  onChange={(e)=>setdata({...data,post_code:e.target.value})} placeholder='Post Code' style={{width:"90%"}}/>

              </div>
            </div>


             <div className='row'>
              <div className='col-3'>
              <label>Contact telephone:</label>

              </div>
              <div className='col-9'>
              <input type='text'  value={data.contact_telephone}   onChange={(e)=>setdata({...data,contact_telephone:e.target.value})} placeholder='Contact telephone' style={{width:"90%"}}/>

              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
              <label>Secondary Telephone:</label>

              </div>
              <div className='col-9'>
              <input type='text'  value={data.secondary_telephone}  onChange={(e)=>setdata({...data,secondary_telephone:e.target.value})} placeholder='Secondary Telephone' style={{width:"90%"}}/>

              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
              <label>Fax number:</label>

              </div>
              <div className='col-9'>
              <input type='text'  value={data.fax_number}  onChange={(e)=>setdata({...data,fax_number:e.target.value})} placeholder='Fax number' style={{width:"90%"}}/>

              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
              <label>E-mail:</label>

              </div>
              <div className='col-9'>
              <input type='text'  value={data.email}  onChange={(e)=>setdata({...data,email:e.target.value})} placeholder='E-mail' style={{width:"90%"}}/>

              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
              <label>Webpage:</label>

              </div>
              <div className='col-9'>
              <input type='text'  value={data.webpage}  onChange={(e)=>setdata({...data,webpage:e.target.value})} placeholder='Webpage' style={{width:"90%"}}/>

              </div>
            </div>
       
        </div>
        <div className='postage_defination'>
            <h6>Other</h6>
            <div className='row'>
              <div className='col-3'>
              <label>Supplier Name:</label>

              </div>
              <div className='col-9'>
              <select value={data.currency_id} type='text' onChange={(e)=>setdata({...data,currency_id:e.target.value})} placeholder='Supplier Name' style={{width:"90%",padding:"0.5rem"}}>
              {currencyList?.map((item)=>{
                  return(
                    <option value={item.id}>{item.name}</option>
                 
              )
              })}

              </select>
              </div>
            </div>
          
           
        </div>
          </div>
    </div>
    {/* <div className="modal-footer">
      <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      <button type="button" onClick={create} className="btn btn-primary">Save changes</button>
      
    </div> */}
    <div className="modal-footer">
                  <button type="button" onClick={create} data-dismiss="modal" className="btnn"><FiSave /> Save</button>
                  <button type="button" className="btnn1" data-dismiss="modal">Cancel</button>
                </div>
  </div>
</div>
</div>


      {/* <button  ></button> */}
      <button disabled={select.length>0?false:true} onClick={()=>delsupplier(select)}  style={{fontSize:"14px",padding:"3px 10px"}}><RiDeleteBin5Line style={{fontSize:"14px"}} /> Delete</button>      </div>
    </div>
    <div className='table_box my-3'>
        <table className="table table-responsive">
          <thead>
            <tr style={{ background: "#F3F3F4" }}>
            <th scope="col"></th>
   <th style={{width:"100%"}}>Name</th>
   <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {supplier?.map((item, i) => {
              return (
                <tr onClick={(e) => getselect(item.id, e)} style={{background:`${isRowSelected(item?.id) ?"#93B1C9":""}`,color:`${isRowSelected(item?.id) ? "white" : ""}`}} className={`${i % 2 === 0 ? "" : "bg"}`}>
                  <th scope="row"></th>
                  <td className={`${itemId==item.id?"text-white":""}`}>{item.supplier_name}</td>
                  <td>
      <div>
  {/* Button trigger modal */}
  <button onClick={()=>setdata(item)}  type="button" data-toggle="modal" data-target="#exampleModalCenter1">
<FiEdit/>
</button>
  {/* Modal */}
  <div className="modal fade bd-example-modal-lg my-5" id="exampleModalCenter1" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle1" aria-hidden="true">
<div className="modal-dialog  modal-lg modal-dialog-centered" role="document">
  <div className="modal-content">
    <div className="modal-header">
      <h5 className="modal-title" id="exampleModalCenterTitle">Create Supplier</h5>
      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true" ><Close style={{fontSize:"25px",fontWeight:"bold",color:"lightgray"}}/></span>
      </button>
    </div>
    <div className="modal-body">
<div className='container'>

    <div className='postage_defination'>
            <h6>Name</h6>
            <div className='row'>
              <div className='col-3'>

              <label>Supplier Name:</label>
              </div>
              <div className='col-9'>

              <input type='text' value={data.supplier_name} onChange={(e)=>setdata({...data,supplier_name:e.target.value})} placeholder='Supplier Name' style={{width:"90%"}}/>
              
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>

              <label>Contact Name:</label>
              </div>
              <div className='col-9'>
                
              <input type='text' value={data.contact_name}   onChange={(e)=>setdata({...data,contact_name:e.target.value})}  placeholder='Contact Name' style={{width:"90%"}}/>
              </div>
            </div>
         
        </div>
        <div className='postage_defination'>
            <h6>Contact Data</h6>
            <div className='row'>
              <div className='col-3'>

              <label>Address:</label>
              </div>
              <div className='col-9'>
              <input type='text' value={data.address}     onChange={(e)=>setdata({...data,address:e.target.value})}  placeholder='Address' style={{width:"90%"}}/>
                
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>

              <label>Alternative Address:</label>
              </div>
              <div className='col-9'>
              <input type='text' value={data.alternative_add}     onChange={(e)=>setdata({...data,alternative_add:e.target.value})}  placeholder='Alternative Address' style={{width:"90%"}}/>
                
              </div>
            </div>
          
            <div className='row'>
              <div className='col-3'>

              <label>City / Town:</label>
              </div>
              <div className='col-9'>
              <input type='text' value={data.city_town}     onChange={(e)=>setdata({...data,city_town:e.target.value})}  placeholder='City / Town' style={{width:"90%"}}/>
                
              </div>
            </div>
       
            <div className='row'>
              <div className='col-3'>
              <label>Region:</label>

              </div>
              <div className='col-9'>
              <input type='text' value={data.region}     onChange={(e)=>setdata({...data,region:e.target.value})}  placeholder='Region' style={{width:"90%"}}/>
                
              </div>
            </div>
          
            <div className='row'>
              <div className='col-3'>

              <label>Country:</label>
              </div>
              <div className='col-9'>
                
              <input type='text' value={data.country}    onChange={(e)=>setdata({...data,country:e.target.value})} placeholder='Country' style={{width:"90%"}}/>
              </div>
            </div>
          
            <div className='row'>
              <div className='col-3'>

              <label>Post Code:</label>
              </div>
              <div className='col-9'>
                
              <input type='text' value={data.post_code}    onChange={(e)=>setdata({...data,post_code:e.target.value})} placeholder='Post Code' style={{width:"90%"}}/>
              </div>
            </div>
       
            <div className='row'>
              <div className='col-3'>
              <label>Contact telephone:</label>

              </div>
              <div className='col-9'>
                
              <input type='text' value={data.contact_telephone}    onChange={(e)=>setdata({...data,contact_telephone:e.target.value})} placeholder='Contact telephone' style={{width:"90%"}}/>
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
              <label>Secondary Telephone:</label>

              </div>
              <div className='col-9'>
                
              <input type='text' value={data.secondary_telephone}    onChange={(e)=>setdata({...data,secondary_telephone:e.target.value})} placeholder='Secondary Telephone' style={{idth:"90%"}}/>
              </div>
            </div>
            
            <div className='row'>
              <div className='col-3'>
              <label>Fax number:</label>
              </div>
              <div className='col-9'>
              <input type='text' value={data.fax_number}    onChange={(e)=>setdata({...data,fax_number:e.target.value})} placeholder='Fax number' style={{width:"90%"}}/>
              </div>
            </div>
        
            <div className='row'>
              <div className='col-3'>
              <label>E-mail:</label>
              </div>
              <div className='col-9'>
              <input type='text' value={data.email}    onChange={(e)=>setdata({...data,email:e.target.value})} placeholder='E-mail' style={{width:"90%"}}/>
              </div>
            </div>
          
            <div className='row'>
              <div className='col-3'>

              <label>Webpage:</label>
              </div>
              <div className='col-9'>
                
              <input type='text' value={data.webpage}    onChange={(e)=>setdata({...data,webpage:e.target.value})} placeholder='Webpage' style={{width:"90%"}}/>
              </div>
            </div> 
          
             
        </div>
        <div className='postage_defination'>
            <h6>Other</h6>
            <div className='row'>
              <div className='col-3'>
              <label>Currency:</label>

              </div>
              <div className='col-9'>
                
              <select type='text' onChange={(e)=>setdata({...data,currency_id:e.target.value})} placeholder='Supplier Name' style={{width:"90%",padding:"0.5rem"}}>
              {currencyList?.map((item)=>{
                  return(
                    <option value={item.id}>{item.name}</option>
                 
              )
              })}

              </select>
              </div>
            </div>
          
           
        </div>
          </div>
    </div>
    <div className="modal-footer">
                  <button type="button" onClick={()=>editsupplier(data)} data-dismiss="modal" className="btnn"><FiSave /> Save</button>
                  <button type="button" className="btnn1" data-dismiss="modal">Cancel</button>
                </div>
    {/* <div className="modal-footer">
      <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      <button type="button" onClick={()=>editsupplier(data)} className="btn btn-primary">Save changes</button>
      
    </div> */}
  </div>
</div>
</div>
</div>
        </td>

                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
 </div></div>
  )
}

export default Supplier