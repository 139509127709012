import { BrowserRouter, Route, Routes} from 'react-router-dom';
import './App.css';
import Login from './Pages/Login';
import Register from './Pages/Register';
import ForgetPassword from './Pages/ForgetPassword';
import RestPassword from './Pages/RestPassword';
import SiderBar from './Component/SiderBar';
import Inventory from './Pages/Inventory';
import Category from './Pages/Category';
import Supplier from './Pages/Supplier';
import WeraHouse from './Pages/WeraHouse';
import Location from './Pages/Location';
import NewStockTransfer from './Pages/NewStockTransfer';
import NewStockRequest from './Pages/NewStockRequest';
import ReorderLowStock from './Pages/ReorderLowStock';
import AmazonVendor from './Pages/AmazonVendor';
import StockIn from './Pages/StockIn';
import StockCount from './Pages/StockCount';
import Variation from './Variation';
import ParchaseOrder from './Pages/ParchaseOrder';
import CreateNewOp from './Pages/CreateNewOp';
import EditPO from './Component/EditPO';
import ScrabItems from './Pages/ScrabItems';
import ProcessedOrder from './Pages/ProcessedOrder';
import DispatchConsole from './Pages/DispatchConsole';
// import ResizableTable from './Pages/ResizeableTable';
import Transferstatus from './Pages/Transferstatus';
import StartRecuving from './Pages/StartRecuving';
import MarkRecived from './Pages/MarkRecived';
import ViewTransfer from './Pages/ViewTransfer';
import ProcessedDetail from './Component/ProcessedDetail';
import RuleEngen from './Pages/RuleEngen';
import ColumnSorting from './Component/Edit/ColumnSorting';
// import { useEffect } from 'react';
// import Tine from './Component/Tine';

function App() {

  return (
<BrowserRouter>
<Routes>
  <Route path='/' element={<Login/>}/>
  <Route path='/sign-up' element={<Register />}/>
  <Route path='/foget-Password' element={<ForgetPassword />}/>
  <Route path='/Rest-Password' element={<RestPassword />}/>
  <Route path='/ColumnSorting' element={<ColumnSorting />}/>
  <Route path='/dashboard' element={<SiderBar/>}>
  <Route path='/dashboard/Inventory' element={<Inventory/>}/>
  <Route path='/dashboard/category' element={<Category/>}/>
  <Route path='/dashboard/supplier' element={<Supplier/>}/>
  <Route path='/dashboard/warehouse' element={<WeraHouse/>}/>
  <Route path='/dashboard/location' element={<Location/>}/>
  <Route path='/dashboard/New-stock-transfer' element={<NewStockTransfer/>}/>
  <Route path='/dashboard/New-stock-request' element={<NewStockRequest/>}/>
  <Route path='/dashboard/Reorder-low-stock' element={<ReorderLowStock/>}/>
  <Route path='/dashboard/Amazon-Vendor-centrel' element={<AmazonVendor/>}/>
  <Route path='/dashboard/stock-in' element={<StockIn/>}/>
  <Route path='/dashboard/stock-count' element={<StockCount/>}/>
  <Route path='/dashboard/variation' element={<Variation/>}/>
  <Route path='/dashboard/PurchaseOrder' element={<ParchaseOrder/>}/>
  <Route path='/dashboard/Purchase-order' element={<CreateNewOp/>}/>
  <Route path='/dashboard/scrab-item' element={<ScrabItems/>}/>
  <Route path='/dashboard/ProcessedOrders' element={<ProcessedOrder/>}/>
  <Route path='/dashboard/dispatchConsole' element={<DispatchConsole/>}/>
  <Route path='/dashboard/Edit-Purchase-order/:id' element={<EditPO/>}/>
  <Route path='/dashboard/trsnfer/:id' element={<Transferstatus/>}/>
  <Route path='/dashboard/start-reciving/:id' element={<StartRecuving/>}/>
  <Route path='/dashboard/mark-reciving/:id' element={<MarkRecived/>}/>
  <Route path='/dashboard/recived/:id' element={<ViewTransfer/>}/>
  <Route path='/dashboard/Processed-Detail' element={<ProcessedDetail/>}/>
  <Route path='/dashboard/rule-engine' element={<RuleEngen/>}/>
  </Route>
</Routes>
</BrowserRouter>
  );
}

export default App;
